<template>
  <div class="devicesBackGround" id="devicesAll">
    <div id="appbar">
      <text @click="navToHome" class="title" id="hiddenTitle">
        &nbsp; &nbsp; &nbsp; &nbsp;DDC IoT Platform</text
      >

      <div class="container">
        <div class="item">
          <img :src="require('../../assets/logo2.png')" />
        </div>
      </div>
      <button
        type="button"
        id="navButtonHomeDevices"
        class="btn btn-success"
        @click="navToHome"
      >
        <i class="fa-solid fa-house"></i> <text class="navText">Home</text>
      </button>
      <button
        type="button"
        id="navButtonDomainsDevices"
        class="btn btn-success"
        @click="navToDomains"
      >
        <i class="fa-solid fa-layer-group"></i>
        <text class="navText"> Domains</text>
      </button>
      <button
        type="button"
        id="navButtonActiveDevices"
        class="btn btn-success"
        @click="navToDevices"
      ><i class="fas fa-hdd"></i><text class="navText"> Devices</text>
      </button>
      <button
        type="button"
        id="navButtonUsersDevices"
        class="btn btn-success"
        @click="navToUsers"
      >
        <i class="fa-solid fa-users"></i> <text class="navText">Users</text>
      </button>
      <router-link to="/domains" class="Router-link"
        ><i class="fas fa-layer-group"></i>
      </router-link>
      <router-link to="/devices" class="Router-link"
        ><i class="fas fa-chalkboard"></i
      ></router-link>
      <router-link to="/users" class="Router-link"
        ><i class="fas fa-user-friends"></i> Users</router-link
      >
      <div id="profileIcon">
        <button
          type="button"
          class="btn btn-success"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          id="logoutButtonDevices"
          @click="profilename"
        >
          <i class="fas fa-user-alt" id="pIcon"></i>&ensp;
          <label class="usernameText">{{ this.user }}</label>

          <i class="fa-solid fa-caret-down" id="pIcon"></i> &nbsp;
        </button>
        <div class="dropdown-menu">
          <a @click="navToSettings" class="dropdown-item" id="blockedContent"
            >Settings <i class="fas fa-cogs" id="settingsIcon"></i>
          </a>
          <a @click="logout" class="dropdown-item"
            >Logout <i class="fas fa-sign-out-alt" id="logoutIcon"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="devicesCard" id="deviceContainer">
      <h4 class="actualDomainText">Domain: {{ this.actualDomain }}</h4>
      <h4 id="devicesText">Devices</h4>
      <h4 id="devicesError">{{ error }}</h4>
      <!-- ----------------------------SEARCH BAR----------------------- -->
      <div id="searchDiv">
        <input
          type="text"
          class="form-control"
          placeholder="Search..."
          id="searchBar"
          @keydown="isSearchEnter()"
        />
        <button class="searchButton" @click="searchName()" id="searchButton">
          <i id="filterIcon" class="fas fa-filter"></i>
        </button>
        <!-- ---------------------------------Create button------------------------------------------- -->

        <button
          type="button"
          class="btn btn-outline-success"
          id="deviceCreateButton"
          data-bs-toggle="modal"
          data-bs-target="#exampleModalCreate"
        >
          <i class="fas fa-plus"></i>
          <text id="hiddenDeviceCreateText"> Create</text>
        </button>
        <div class="btn-group" id="exportButtonDiv">
          <button
            type="button"
            id="exportButton"
            class="btn btn-outline-success"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-file-csv" id="exportIcon"></i>
            <text class="exportButtonText" id="hiddenEditText"> Export</text>
            <i class="fa-solid fa-caret-down" id="exportCaret"></i>
          </button>
          <div class="dropdown-menu">
            <a
              class="dropdown-item"
              @click="
                csvExportDevices(
                  names,
                  uuid,
                  physicalID,
                  domainUuid,
                  this.selectedDevice
                )
              "
              ><i class="fas fa-file-csv"></i> Export Device List</a
            >
            <a
              @click="csvExportDeviceData(this.selectedDevice)"
              class="dropdown-item"
              id="exportDropdownItem"
              ><i class="fas fa-file-csv"></i> Export Device Data
            </a>
          </div>
        </div>
      </div>

      <!-- ---------------------------------CREATE MODAL------------------------------------------- -->
      <div
        class="modal fade"
        id="exampleModalCreate"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h2 id="createDeviceTitle">Create Device</h2>
              <h5 class="modal-title" id="exampleModalLabel"></h5>
            </div>
            <div class="modal-body">
              Please fill the form if you want to create a new Device
              <br/>
              <label for="name">Device name:</label>
              <input
                v-model="createName"
                type="text"
                name="Name"
                id="deviceName"
                placeholder="Name"
                @keydown="isCreateEnter"
              />
              <br />
              <br />
              <label for="Physical ID">Device Physical ID:</label>
              <input
                v-model="createPhysicalId"
                type="text"
                name="Physical ID"
                id="devicePhysicalId"
                placeholder="Physical ID"
                @keydown="isCreateEnter"
              />
              <br />
              <br />
            </div>
            <div class="modal-footer">
              <div class="footerButtons">
                <button
                  type="button"
                  id="sureButtonCreate"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                  @click="createDevice()"
                >
                  Create
                </button>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  class="btn btn-danger"
                  id="cancelButtonCreate"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      
      <br />
      <table class="table table-dark" id="devicesTable">
        <tbody>
          <tr>
            <th><input
                class="form-check-input flexCheckDefault"
                type="checkbox"
                @click="checkAllBoxes()"
                value=""
                id="flexCheckDefault"
              /></th>
            <th class="tableCells">Name</th>
            <th class="tableCells">Physical ID</th>
            <th class="tableCells">Uuid</th>
            <th class="tableCells"></th>
            <th class="tableCells"></th>
          </tr>
          <tr
            v-for="(item, index) in num"
            :key="item.uuid"
            class="devicesTableRow"
          >
            <td>
              <input
                class="form-check-input flexCheckDefault"
                type="checkbox"
                @click="selectDevice(item, index)"
                value=""
                :id="'flexCheckDefault'+index"
              />
            </td>
            <td class="tableCells" @click="showDevice(item)">
              {{ names[index] }}
            </td>
            <td class="tableCells" @click="showDevice(item)">
              {{ physicalID[index] }}
            </td>
            <td class="tableCells" @click="showDevice(item)">
              {{ uuid[index] }}
            </td>
            <td class="tableCells" id="editCell"></td>
            <td class="tableCells">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalDelete"
                id="deviceDeleteButton"
                @click="deleteDeviceModal(item, index)"
              >
                <i class="fas fa-trash-alt" id="trashIcon"></i>
                <text id="hiddenDeleteText">&nbsp;Delete</text>
              </button>
              <button
                id="deviceEditButton"
                type="button"
                class="btn"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalEdit"
                @click="editDeviceModal(item, index)"
              >
                <i class="far fa-edit"></i>
                <text id="hiddenEditText"> Edit</text>
              </button>

              <!-- ------------------------------------- DELETE MODAL -------------------------------------------- -->
              <div
                class="modal fade"
                id="exampleModalDelete"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel"></h5>
                    </div>
                    <div class="modal-body">
                      Are you sure you want to delete "{{
                        names[this.deleteIndex]
                      }}" device?
                    </div>
                    <div class="modal-footer">
                      <div class="footerButtons">
                        <button
                          type="button"
                          id="sureButtonDelete"
                          class="btn btn-success"
                          data-bs-dismiss="modal"
                          @click="
                            deleteDevice(this.deleteSelected, this.deleteIndex)
                          "
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          class="btn btn-danger"
                          id="cancelButtonDelete"
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- ------------------------------------------ EDIT MODAL ----------------------------------------- --> 
              <div
                class="modal fade"
                id="exampleModalEdit"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h2 id="editDeviceTitle">
                        Editing Device: {{ this.editName }}
                      </h2>
                      <h5 class="modal-title" id="exampleModalLabel"></h5>
                    </div>
                    <div class="modal-body">
                      Please fill the form if you want to edit this Device.
                      <br />
                      <br />
                      <label for="Name">Device name:</label>
                      <input
                        v-model="editName"
                        type="text"
                        name="Name"
                        id="deviceEditName"
                        :placeholder="this.editName"
                        @keydown="isEditEnter"
                      />
                      <br />
                      <br />
                      <label for="info">Device Physical Id:</label>
                      <input
                        v-model="editPhysicalId"
                        type="text"
                        name="PhysicalId"
                        id="deviceEditPhysicalId"
                        :placeholder="this.editPhysicalId"
                        @keydown="isEditEnter"
                      />
                      <br />
                      <br />
                    </div>
                    <div class="modal-footer">
                      <div class="footerButtons">
                        <button
                          type="button"
                          id="sureButtonEdit"
                          class="btn btn-success"
                          data-bs-dismiss="modal"
                          @click="editDevice(item)"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          class="btn btn-danger"
                          id="cancelButtonEdit"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="spinner-container" id="spinner-devices">
     <atom-spinner
          :animation-duration="1000"
          :size="120"
          :color="'#42ba85'"
          :position="absolute"
     />
     </div>
  </div>
</template>

<script>
import ddcIotDataProvider from "../../utils/ddcIotDataProvider";
import ddcIotAuthProvider from "../../utils/ddcIotAuthProvider.js";


  import {AtomSpinner} from 'epic-spinners'


const apiUrl = process.env.VUE_APP_API_URL || "http://localhost";
const authProvider = ddcIotAuthProvider(apiUrl);
const dataProvider = ddcIotDataProvider(apiUrl);

export default {
   components: {
      AtomSpinner
    },
  ///Functions u can call in. For example do something when a button is pushed.
  ///To reach data from data() where you eclare variables use this.somethingd
  methods: {
    checkAllBoxes(){
      for (let index = 0; index < this.num.length; index++) {
        document.getElementById("flexCheckDefault"+index).click()
        
      }
    },
    //----------------------------SEARCH BAR----------------------- -->
    searchName() {
      var input, filter, table, tr, td, i, txtValue;
      input = document.getElementById("searchBar");
      filter = input.value.toUpperCase();
      table = document.getElementById("devicesTable");
      tr = table.getElementsByTagName("tr");
     // console.log(filter, tr.length);
      for (i = 1; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[1];
        if (td) {
          txtValue = td.textContent || td.innerText;
         // console.log(input);
          if (txtValue.toUpperCase().includes(filter)) {
            tr[i].style.display = "";
          } else {
           // console.log(txtValue.toUpperCase(), filter);
            tr[i].style.display = "none";
          }
        }
      }
    },
    isSearchEnter() {
      if (event.keyCode == 13) {
        document.getElementById("searchButton").click();
      }
    },
    ////-------------------------------------------CREATE------------------------------------------------
    isCreateEnter() {
      if (event.keyCode == 13) {
        document.getElementById("sureButtonCreate").click();
      }
    },
    ///-------------------------------------------------------EDIT--------------------------------------------
    isEditEnter() {
      if (event.keyCode == 13) {
        document.getElementById("sureButtonEdit").click();
      }
    },
    ///---------------------------------------------SEARCH INDEX OF...--------------------------------------
    searchIndex(array) {
      let gpsIndex;
      let networkIndex;
      let errorIndex;
      let batteryIndex;
      for (let i in array.records) {
        if (array.records[i].kind == "@gps_location") {
          gpsIndex = i;
        }
        if (array.records[i].kind == "@battery_info") {
          batteryIndex = i;
        }
        if (array.records[i].kind == "@network_info") {
          networkIndex = i;
        }
        if (array.records[i].kind == "@error") {
          errorIndex = i;
        }
      }
      return { gpsIndex, batteryIndex, networkIndex, errorIndex };
    },
    //-------------------------------CHECKBOX------------------------------------------------------------------------
    selectDevice(item, index) {
      if (!this.selectedDevice.includes(item.uuid)) {
        console.log(index);
        this.selectedDevice.push(item.uuid);
      } else {
        this.selectedDevice.forEach((element, index) => {
          if (element.includes(item.uuid)) {
          //  console.log(index);
            this.selectedDevice.splice(index, 1);
          }
        });
      }
    },

    //-----------------------------------------------CREATE CSV FILE--------------------------------------------------------
    csvExportDevices(names, uuid, physicalID, DomainUuid, uuidToExprt) {
    //  console.log(names, uuid, physicalID);
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += "Name;UUID;PhysicalID;Domain UUID;\n";
      names.forEach((element, index) => {
        if (uuidToExprt.includes(uuid[index])) {
          csvContent +=
            names[index] +
            ";" +
            uuid[index] +
            ";" +
            "/" +
            physicalID[index] +
            "/" +
            ";" +
            DomainUuid[index] +
            ";" +
            "\n";
        }
      });
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
    //------------------------------------------ EXPORT---------------------------------------------------------
    async csvExportDeviceData(exportArray) {
      alert("Starting export... Click OK and wait until it's done.");
      document.getElementById("exportButton").setAttribute('disabled', true)
      let csvContent = "data:text/csv;charset=utf-8,";
      for await (let uuidData of exportArray) {
      //  console.log(uuidData);
        let params = {
          uuid: uuidData,
          id: uuidData,
        };
        let deviceData = await dataProvider.getOne("devices", params);
       // console.log(deviceData);

        csvContent += "Device name:;" + deviceData.data.name + "\n";
        csvContent += "Physical ID:;" + deviceData.data.physicalId + "\n";
        csvContent += "UUID:;" + deviceData.data.uuid + "\n";
        csvContent += "\n";
        csvContent +=
          "Time;Latitude;Longitude;Altitude;Battery (%);Signal Strength (%);ErrorCode;Error Msg;\n";

        let db = await dataProvider.getStatusListCount("devices", params);
        let pagenum = db.data / 400;
        for (let index = 0; index < Math.ceil(pagenum); index++) {
          let res = await dataProvider.getStatusList(
            "devices",
            deviceData.data.uuid,
            400,
            index
          );
        //  console.log(res);
          res.data.forEach((element) => {
            let indexes = this.searchIndex(element);
            let long;
            let lati;
            let alti;
            let battery;
            let signal;
            if (indexes.gpsIndex != undefined) {
              long = JSON.parse(
                element.records[indexes.gpsIndex].data
              ).longitude;
              lati = JSON.parse(
                element.records[indexes.gpsIndex].data
              ).latitude;
              alti = JSON.parse(
                element.records[indexes.gpsIndex].data
              ).altitude;
            } else {
              long = "";
              lati = "";
              alti = "";
            }
            if (indexes.batteryIndex != undefined) {
              battery = JSON.parse(element.records[indexes.batteryIndex].data)
                .level.value;
            } else {
              battery = "";
            }
            if (indexes.networkIndex != undefined) {
              signal = JSON.parse(element.records[indexes.networkIndex].data)
                .signal_strength.value;
            } else {
              signal = "";
            }
            //if (indexes.errorIndex != undefined) {
            //  signal = JSON.parse(element.records[indexes.errorIndex].data)
            //     .signal_strength.value;
            //} else {
            //  signal = "";
            //}
            csvContent +=
              element.timestamp +
              ";" +
              long +
              ";" +
              lati +
              ";" +
              alti +
              ";" +
              battery +
              ";" +
              signal +
              ";" +
              //errorCode[index] +
              //";" +
              //errorData[index] +
              //";" +
              "\n";
          });
        }
      }
      //Promise.all(promis).then(() => {
      alert("done");
      //document.getElementById("exportButtonId").innerHTML=oldName
      document.getElementById('exportButton').removeAttribute("disabled");
      csvContent += "\n";
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
    //-------------------------------------------LOGOUT------------------------------------------------------
    logout() {
      authProvider.logout();
      this.loggedIn = false;
      this.$router.push("/Login");
     // console.log(this.loggedIn);
      localStorage.setItem("username", "");
    },
    //----------------------------------------------------THEME--------------------------------------------------
    themeChanger() {
      var themeValue = localStorage.getItem("theme");

      if (themeValue == "light") {
        document.getElementById("devicesAll").style.backgroundColor = "white";

        document.getElementById("navButtonUsers").style.color = "black";
        document.getElementById("navButtonUsers").style.backgroundColor =
          "#d2d3db";
        document.getElementById("navButtonDomains").style.color = "black";
        document.getElementById("navButtonDomains").style.backgroundColor =
          "#d2d3db";
        document.getElementById("navButtonHome").style.backgroundColor =
          "#d2d3db";
        document.getElementById("navButtonHome").style.color = "black";
        document.getElementById("navButtonActive").style.backgroundColor =
          "#212529";
        document.getElementById("navButtonActive").style.color = "white";
        document.getElementById("appbar").style.backgroundColor = "#d2d3db";
        document.getElementById("logoutButton").style.backgroundColor =
          "#d2d3db";
        document.getElementById("logoutButton").style.color = "black";
        document.getElementById("deviceContainer").style.backgroundColor =
          "#d2d3db";
        document.getElementById("deviceContainer").style.color = "black";
        document.getElementById("logo").style.color = "black";
        document.getElementById("deviceCreateButton").style.backgroundColor =
          "#d2d3db";
        document.getElementById("deviceCreateButton").style.color = "black";
        document.getElementById("deviceCreateButton").style.border =
          "1px solid black";
        document.getElementById("devicesTable").className = "table";
        document.getElementById("devicesTable").style.color = "black";
      }

      if (themeValue == "blue") {
        document.getElementById("devicesAll").style.backgroundColor = "white";
        document.getElementById("navButtonUsers").style.backgroundColor =
          "dodgerblue";
        document.getElementById("navButtonUsers").style.color = "white";
        document.getElementById("navButtonDomains").style.backgroundColor =
          "dodgerblue";
        document.getElementById("navButtonDomains").style.color = "white";
        document.getElementById("navButtonHome").style.backgroundColor =
          "dodgerblue";
        document.getElementById("navButtonHome").style.color = "white";
        document.getElementById("navButtonActive").style.backgroundColor =
          "dodgerblue";
        document.getElementById("navButtonActive").style.color = "white";
        document.getElementById("appbar").style.backgroundColor = "dodgerblue";
        document.getElementById("logoutButton").style.backgroundColor =
          "dodgerblue";
        document.getElementById("logoutButton").style.color = "white";
        document.getElementById("deviceContainer").style.backgroundColor =
          "dodgerblue";
        document.getElementById("deviceContainer").style.color = "white";
        document.getElementById("logo").style.color = "white";
        document.getElementById("deviceCreateButton").style.backgroundColor =
          "dodgerblue";
        document.getElementById("deviceCreateButton").style.color = "white";
        document.getElementById("deviceCreateButton").style.border =
          "1px solid white";
        document.getElementById("devicesTable").className = "table";
        document.getElementById("devicesTable").style.color = "white";
      }

      if (themeValue == "green") {
        document.getElementById("devicesAll").style.backgroundColor = "white";
        document.getElementById("navButtonUsers").style.backgroundColor =
          "#42ba85";
        document.getElementById("navButtonUsers").style.color = "white";
        document.getElementById("navButtonDomains").style.backgroundColor =
          "#42ba85";
        document.getElementById("navButtonDomains").style.color = "white";
        document.getElementById("navButtonHome").style.backgroundColor =
          "#42ba85";
        document.getElementById("navButtonHome").style.color = "white";
        document.getElementById("navButtonActive").style.backgroundColor =
          "#42ba85";
        document.getElementById("navButtonActive").style.color = "white";
        document.getElementById("appbar").style.backgroundColor = "#42ba85";
        document.getElementById("logoutButton").style.backgroundColor =
          "#42ba85";
        document.getElementById("logoutButton").style.color = "white";
        document.getElementById("deviceContainer").style.backgroundColor =
          "#42ba85";
        document.getElementById("deviceContainer").style.color = "white";
        document.getElementById("deviceCreateButton").style.backgroundColor =
          "#42ba85";
        document.getElementById("deviceCreateButton").style.color = "white";
        document.getElementById("deviceCreateButton").style.border =
          "1px solid white";
        document.getElementById("logo").style.color = "white";
        document.getElementById("devicesTable").className = "table";
        document.getElementById("devicesTable").style.color = "white";
      }

      if (themeValue == "cyan") {
        document.getElementById("devicesAll").style.backgroundColor = "#2a323d";
        document.getElementById("navButtonUsers").style.backgroundColor =
          "#159fba";
        document.getElementById("navButtonUsers").style.color = "#2a323d";
        document.getElementById("navButtonDomains").style.backgroundColor =
          "#159fba";
        document.getElementById("navButtonDomains").style.color = "#2a323d";
        document.getElementById("navButtonHome").style.backgroundColor =
          "#159fba";
        document.getElementById("navButtonHome").style.color = "#2a323d";
        document.getElementById("navButtonActive").style.backgroundColor =
          "#2a323d";
        document.getElementById("navButtonActive").style.color = "#159fba";
        document.getElementById("appbar").style.backgroundColor = "#159fba";
        document.getElementById("logoutButton").style.backgroundColor =
          "#159fba";
        document.getElementById("logoutButton").style.color = "#2a323d";
        document.getElementById("deviceContainer").style.backgroundColor =
          "#159fba";
        document.getElementById("deviceContainer").style.color = "#2a323d";
        document.getElementById("deviceCreateButton").style.backgroundColor =
          "#2a323d";
        document.getElementById("deviceCreateButton").style.color = "#159fba";
        document.getElementById("deviceCreateButton").style.border =
          "1px solid #159fba";
        document.getElementById("logo").style.color = "#2a323d";
        document.getElementById("devicesTable").className = "table";
        document.getElementById("devicesTable").style.color = "#2a323d";
      }
    },
    //---------------------------------NAVBAR------------------------------------------------------------------------

    titleHide() {
      if (window.width <= 5000) {
        document.getElementById("hiddenTitle").style.color = "red";
      }
    },

    notSure() {
      document.getElementById("popover").style.zIndex = -5;
    },

    navToDevices() {
      this.$router.push("/devices");
    },

    navToHome() {
      this.$router.push("/");
    },

    navToDomains() {
      this.$router.push("/domains");
    },

    navToSettings() {
      this.$router.push("/settings");
    },

    navToUsers() {
      this.$router.push("/users");
    },
    //-----------------------------------TABLE Functions----------------------------------------------------------
    showDevice(params) {
     // console.log(params);
      localStorage.setItem("deviceShow", params.uuid);
      this.$router.push("/devicesShow");
    },
    //-----------------------------------CREATE----------------------------------------------------------
    async createDevice() {
      const devices = await dataProvider.getList("devices");
      //console.log(devices);
      let wrong = false;
      for await (let rows of devices.data) {
        //console.log(rows);
        if (
          rows.name == this.createName ||
          rows.physicalId == this.createPhysicalId
        ) {
          wrong = true;
        }
      }
      if (wrong == true) {
        this.error = "Device already exists";
      } else {
        this.error = "";
        this.DomainUuid = localStorage.getItem("domain");
        var params = {
          data: {
            domainUuid: this.DomainUuid,
            name: this.createName,
            physicalId: this.createPhysicalId,
          },
        };
        try {
          await dataProvider.create("devices", params);
         // console.log("Create device!");
          const newArray = await dataProvider.getList("devices");
         // console.log(newArray);
          this.names = [];
          this.uuid = [];
          this.physicalID = [];
          for (let rows of newArray.data) {
            this.names.push(rows.name);
            this.uuid.push(rows.uuid);
            this.physicalID.push(rows.physicalId);
          }
          this.num = newArray.data;
         // console.log(this.names, this.uuid, this.physicalID);
        } catch (e) {
          this.error = e;
         // console.log(e);
          if (e == "Error: Request failed with status code 500") {
            this.error =
              "Create was successfull, but PhysicalId is already in use in an other domain, please edit your device's physicalID";
            const newArray = await dataProvider.getList("devices");
            //console.log(newArray);
            this.names = [];
            this.uuid = [];
            this.physicalID = [];
            for (let rows of newArray.data) {
              this.names.push(rows.name);
              this.uuid.push(rows.uuid);
              this.physicalID.push(rows.physicalId);
            }
            this.num = newArray.data;
          }
          if(e == "Error: Request failed with status code 400"){
            this.error =
              "Something went wrong!";
          }
        }
      }
    },
    /* ---------------------------------------------EDIT---------------------------------------------------- */
    async editDeviceModal(item, index) {
      this.editSelected = item.uuid;
      this.editName = item.name;
      this.editPhysicalId = item.physicalId;
      this.editIndex = index;
    // console.log(item, index);
    },
    async editDevice() {
      let params = {
        data: {
          name: this.editName,
          physicalId: this.editPhysicalId,
          uuid: this.editSelected,
        },
      };
      let wrong = false;
      let devices = await dataProvider.getList("devices");
      let index = -1;
      for await (let rows of devices.data) {
        index++;
        if (
          (rows.name == this.editName ||
            rows.physicalId == this.editPhysicalId) &&
          this.editIndex != index
        ) {
          wrong = true;
        }
      }
      if (wrong == true) {
        this.error = "Device already exists";
      } else {
      // console.log(params);
        this.error = "";
        try {
          await dataProvider.update("devices", params);
        } catch (e) {
          if (e == "Error: Request failed with status code 500") {
            this.error =
              "Edit was successfull, but PhysicalId is already in use in an other domain, please edit your device's physicalID";
          }
        }
       // console.log("Edit Device!");
        this.names[this.editIndex] = this.editName;
        this.physicalID[this.editIndex] = this.editPhysicalId;
        let res = await dataProvider.getList("devices");
        this.names = [];
        this.uuid = [];
        this.physicalID = [];
        for await (let rows of res.data) {
          this.names.push(rows.name);
          this.uuid.push(rows.uuid);
          this.physicalID.push(rows.physicalId);
        }
        this.num = res.data;
      //  console.log(this.names, this.uuid, this.physicalID);

        /* .catch((err) => {
            this.error = err;
             if (err == "Error: Request failed with status code 500") {
              this.error =
                "Edit was successfull, but PhysicalId is already in use in an other domain, please edit your device's physicalID";
            } 
          }); */
      }
    },
    /* -------------------------------------------------------------DELETE---------------------------------------------------- */
    deleteDeviceModal(item, index) {
      this.deleteIndex = index;
      this.deleteSelected = item.uuid;
     // console.log(this.deleteSelected, this.deleteIndex);
    },

    deleteDevice(params, index) {
     // console.log(params, index);
      var param = {
        id: params,
      };

      //console.log("Delete Device!");
      dataProvider.delete("devices", param).then(() => {});
      this.num.splice(index, 1);
      this.names.splice(index, 1);
      this.uuid.splice(index, 1);
      this.physicalID.splice(index, 1);
     // console.log(this.num);
    },
  },
  // /------------------------------------------MOUNTED--------------------------------------------------
  async mounted() {
   // console.log("mounted!");
   document.getElementById("spinner-devices").style.display = "block";
    this.themeChanger();
    let params = {
      uuid: localStorage.getItem("domain"),
    };
    let domainInfo = await dataProvider.getOne("domains", params);
    this.actualDomain = domainInfo.data.name;

    dataProvider
      .getList("devices")
      .then((res) => {
        //console.log(res.data);
        if (res.data.length == 0) {
          this.error = "There is no Device to show.";
          document.getElementById("spinner-devices").style.display = "none";
        }
        return (
          res.data.map((data) => {
            this.uuid.push(data.uuid);
            this.names.push(data.name);
            this.physicalID.push(data.physicalId);
            this.domainUuid.push(data.domainUuid);
            document.getElementById("spinner-devices").style.display = "none";
          }),
          (this.num = res.data)
        );
      })
      .catch((err) => console.log(err));
  },
  updated() {
   // console.log("updataed!");
  },
  ///Variables u can declare
  data() {
    return {
      //-------------EDIT-----------
      editSelected: null,
      editName: null,
      editPhysicalId: null,
      //------------Delete-----------
      deleteSelected: null,
      deleteIndex: null,
      //------------ CREATE----------
      createName: null,
      createPhysicalId: null,
      //------------TABLE------------
      names: [],
      uuid: [],
      physicalID: [],

      domainUuid: [],
      indexValue: 0,
      num: 0,
      error: null,
      user: localStorage.getItem("username"),
      actualDomain: null,
      selectedDevice: [],
    };
  },
};
</script>

<style lang="scss" scoped>

/*----------------------- Navbar ---------------------*/
#appbar {
 min-height: 50px;
max-height: 60px;
}

#spinner-devices{
    position: absolute;
  top: 50vh;
  left: 50vw;
  display:none;
}

#hiddenTitle {
  font-size: 20px;
  position: absolute;
  top:0px;
}

/*----------- Home -------------*/

#navButtonHomeDevices {
  background-color: #20262e;
  border: 0;
  position: relative;
   left: 0px;
  margin-right: 5px;
 min-width: 120px;
  width:8vw;
  max-width: 200px;
   height:35px;
}

#navButtonHomeDevices:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonHomeDevices:hover {
  background-color: #42ba85;
  color: black;
}
/*----------- Users -------------*/
#navButtonUsersDevices {
  background-color: #20262e;
  border: 0;
  position: relative;
  left: 0px;
  margin-right: 5px;
  min-width: 120px;
  width:8vw;
  max-width: 200px;
   height:35px;
}

#navButtonUsersDevices:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonUsersDevices:hover {
  background-color: #42ba85;
  color: black;
}
/*----------- Domains -------------*/
#navButtonDomainsDevices {
  background-color: #20262e;
 border: 0;
  position: relative;
  left: 0px;
  margin-right: 5px;
  min-width: 120px;
  width:8vw;
  max-width: 200px;
   height:35px;
}

#navButtonDomainsDevices:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonDomainsDevices:hover {
  background-color: #42ba85;
  color: black;
}
/*----------- Devices -------------*/
#navButtonActiveDevices {
  background-color: #42ba85;
  color: black;
 border: 0;
  position: relative;
  left: 0px;
  margin-right: 5px;
  min-width: 120px;
  width:8vw;
  max-width: 200px;
   height:35px;
}

#navButtonDomainsDevices:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

#navButtonDomainsDevices:hover {
  background-color: #42ba85;
  color: black;
}
/*----------- User, logout -------------*/
#logoutButtonDevices {
   position: fixed;
  float: right;
  right: 30px;
  background-color: #20262e;
  display: flex;
  top: 0.1vh;
  width: 7%;
  border: 0;
  cursor: pointer;
   min-width: 150px;
  max-width: 200px;

}

#logoutButtonDevices:hover {
  color: #42ba85;
}

#logoutButtonDevices:focus {
  outline: none;
  box-shadow: none;
}
.usernameText {
  margin-right: 15px;
}
/* ---------------DEVICES CARD STYLE--------------------- */
.devicesBackGround {
  background-color: #2a323d;
  width: 100vw;
   height: 100vh;
  overflow-y: visible;
  overflow-x: hidden;
}
.devicesCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 92.5vw;
  border-radius: 5px;
  top: 12vh;
  position: relative;
  background-color: #212529;
  color: white;
  margin: auto;
}
.devicesCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.actualDomainText {
  color: white;
  position: relative;
  top: 2vh;
  left: 10px;
}
#devicesError {
 position: relative;
  top: 30px;
  left: 15px;
  color:#ff4f60;
  font-size:20px;
}
#devicesText {
  position: relative;
  left: 10px;
  top: 3.5vh;
  
}
/* ---------------SEARCH--------------------- */
#searchDiv {
  position: relative;
  top: 2rem;
  width: 100%;
  height: 3rem;
  margin-bottom: 2rem;
}
#searchBar {
  position: relative;
  margin-left: 10px;
  height: 40px;
  color: white;
  min-width: 110px;
  max-width: 115px;
  background-color: #20262e;
  border-radius: 5px 0px 0px 5px;
  float: left;
  border: 1px solid white;
  
}
#searchButton {
  background-color: #42ba85;
  border: 0;
  color: white;
  height: 40px;
  width: 50px;
  min-width: 50px;
  border-radius: 0px 5px 5px 0px;
  position: relative;
  float: left;
}
/* ------------------DEVICE TABLE-------------------- */
#devicesTable {
  position: relative;
}
.devicesTableRow:hover {
  color: grey;
}

.modal-body{
  font-size:  clamp(12px, 1.5vw, 1rem);
  }

.flexCheckDefault {
  background-color: #2a323d;
  border: 1px solid #42ba85;
}
.checkbox {
  width: 2vh;
}
.table tr {
  cursor: pointer;
}
/* --------------- BUTTON STYLE--------------------- */
.btn {
   width: 7vw;
  min-width: 75px;
  float: right;
  
}
.btn-danger {
  background-color: #dc3545;
  margin-right:5px;
  width: 7vw;
  min-width: 75px;
  float: right;
  max-width:150px;
  min-width:110px;
}
.btn-success {
  border: 0px solid red;
  background-color: #42ba85;
  float: left;
  
}
.btn-success:hover {
  background-color: #35976d;
}
td{
  font-size:  clamp(12px, 1.5vw, 1rem);
}
/* --------------- MODAL STYLE--------------------- */
.modal-backdrop {
  z-index: 4;
  top: 10vh;
  position: absolute;
  top: 100vh;
  background-color: #2a323d;
}
.modal-dialog {
  position: fixed;
  top: 10vh;
  right: 0;
  left: 0;
}

.bodyContent {
  margin: auto;
}
.footerButtons {
  margin: auto;
}
/* --------------- EDIT MODAL STYLE--------------------- */
#deviceEditPhysicalId {
  position: relative;
  float: right;
  margin-right: 6rem;
}
#deviceEditName {
  position: relative;
  float: right;
  margin-right: 6rem;
}
/* --------------- CREATE MODAL STYLE--------------------- */
#deviceName {
  position: relative;
  float: right;
  margin-right: 6rem;
}
#devicePhysicalId {
  position: relative;
  float: right;
  margin-right: 6rem;
}
/* --------------- CANCEL BUTTON STYLE--------------------- */

#cancelButton {
  background-color: #212529;
  border: 1px solid #42ba85;
  width:110px;
  max-width:150px;
  min-width:110px;
}

#cancelButton:hover {
  color: white;
  background-color: #42ba85;
}

#cancelButton:active {
  outline: none;
  box-shadow: none;
}
#cancelButtonCreate {
  position: relative;
  float: right;
  margin-left:3px;
}
#cancelButtonDelete {
  position: relative;
  float: right;
 margin-left:3px;
  width:110px;
  max-width:150px;
  min-width:110px;
}
#cancelButtonEdit {
  position: relative;
  float: right;
  margin-left:3px;
  width:110px;
  max-width:150px;
  min-width:110px;
}
/* --------------- SURE BUTTON STYLE--------------------- */

#sureButton {
  background-color: #42ba85;
  border: 0;
  width:110px;
  max-width:150px;
  min-width:110px;
}

#sureButton:hover {
  background-color: #35976d;
}

#sureButton:active {
  outline: none;
  box-shadow: none;
}
#sureButtonCreate {
  background-color: #42ba85;
  
  float: left;
  width:110px;
  max-width:150px;
  min-width:110px;
}
#sureButtonDelete {
  background-color: #42ba85;
 
  float: left;
  width:110px;
  max-width:150px;
  min-width:110px;
}
#sureButtonEdit {
  background-color: #42ba85;
 
  float: left;
  width:110px;
  max-width:150px;
  min-width:110px;
}
#sureButtonCreate:hover {
  background-color: #35976d;
}
#sureButtonDelete:hover {
  background-color: #35976d;
}
#sureButtonEdit:hover {
  background-color: #35976d;
}

/* --------------- EDIT BUTTON STYLE--------------------- */

#deviceEditButton {
  background-color: #42ba85;
  color: white;
  margin-right:5px;
  width: 7vw;
  float: right;
  max-width:150px;
  min-width:110px;
}

#deviceEditButton:hover {
  background-color: #35976d;
}
/* --------------- EXPORT BUTTON STYLE--------------------- */
#exportIcon {
  position: relative;
 
  
}
#exportCaret {
  position: relative;
  left:5px;
  
}
#exportButtonDiv {
  position: relative;
  float: right;
  
}
#exportButton {
  background-color: #2a323d;
  color: #42ba85;
  right:10px;
  position: relative;
  border-radius: 5px;
  margin: 3px;
  width:110px;
  max-width:150px;
  min-width:110px;
  
}

#exportButton:hover {
  background-color: #42ba85;
  color: #2a323d;
}

.exportButtonText {
  text-align: center;
  position: relative;
  
}

#exportDropDownItem {
  color: #42ba85;
  background-color: #2a323d;
}
#hiddenEditText{
  text-align: center;
}
/* --------------- CREATE BUTTON STYLE--------------------- */

#deviceCreateButton {
  background-color: #2a323d;
  color: #42ba85;
  position: relative;
  right:12px;
  border-radius: 5px;
  margin: 3px;
  width:110px;
  max-width:150px;
  min-width:110px;
}


#deviceCreateButton:hover {
  background-color: #42ba85;
  color: #2a323d;
}

/* --------------- DELETE BUTTON STYLE--------------------- */
#hiddenDeleteText {
  bottom: 0.5vh;
}
#trashIcon {
  bottom: 0.5vh;
  
}

@media (max-width: 350px) {
  .btn-danger {
  margin-right:5px;
  width: 7vw;
  min-width:120%;
  font-size: 150%;
  max-width:150%;
}
#sureButtonDelete{
    margin-right:5px;
  width: 7vw;
  min-width:120%;
  font-size: 150%;
  max-width:150%;
}
#sureButtonCreate {
  font-size:12px;
  min-width:80px;
  width:90px;
}
#sureButtonEdit {
   font-size:12px;
  min-width:80px;
  width:90px;
}
#cancelButtonEdit {
  width:90px;
  min-width:80px;
  font-size:12px;
}

#deviceName {
 
  float: right;
  margin-right: 30px;
}
#devicePhysicalId {
  
  float: right;
  margin-right: 30px;
}


#cancelButtonCreate {
  min-width:90px;
  font-size:12px;
}
#deviceEditButton {
  margin-right:5px;
  width: 7vw;
  max-width:150%;
  min-width:120%;
    font-size: 150%;
}
#searchBar {
 min-width: 60px;
  max-width: 80px;
  height:35px;
  font-size:12px;
}
#searchButton {
height:35px;
}
   .tableCells {
    font-size:8px;
    width:50%;
  }
  td{
  font-size:  clamp(7px, 0.5vw, 0.4rem);
}
#devicesText {
font-size: 12px;
}
  .actualDomainText{
font-size:12px;
  }
  /*----------- Navbar -------------*/
  #hiddenTitle {
    display: none;
  }

  .navText {
    display: none;
  }

  #navButtonHomeDevices {
    font-size: 80%;
    
    min-width: 12%;
    margin-left: 0%;
  }
  
  #navButtonUsersDevices {
   font-size: 80%;
    
    min-width: 12%;
    margin-left: 0%;
  }

  #navButtonDomainsDevices {
    font-size: 80%;
    min-width: 12%;
    margin-left: 0%;
  }
  
  #navButtonActiveDevices {
     font-size: 80%;
    
    min-width: 12%;
    margin-left: 0%;
  }
  
  #profileIcon {
    position: relative;
  }
  #logoutButtonDevices {
    position: relative;
    float: left;
    right: 100%;
    border: 0;
    width: 10%;
    background-color: #20262e;
    position: relative;
    top: 0%;
  }
  .usernameText {
    display: none;
  }

  .devicesCard {
    margin:auto;
    overflow-y: clip;
    overflow-x: clip;
    
  }
  #devicesTable {
  max-width: 85vw;
  
  }
 
  #hiddenDeleteText {
    display: none;
  }
  #hiddenEditText {
    display: none;
  }
  #deviceEditButton {
    min-width:150%;
   float:left;
  }
  #deviceDeleteButton {
   min-width:150%;
   float:left;
  }
  #deviceCreateButton {
    min-width: 10%;
    max-width: 14%;
    align-items: center;
    margin-right:1%;
    
    
  }
  #hiddenDeviceCreateText {
    display: none;
  }

  #exportButton {
    align-items: center;
   min-width: 30%;
    max-width: 50px;
    top:0%;
    position: absolute;
  }
  .modal-body{
    font-size:7px;
  }
}

/*----------------------------- Logo ---------------------------*/

* {
  box-sizing: border-box;
}
.container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 45px;
  width: 45px;
  margin-top: 2.5px;
  margin-left: 10px;
}
.item {
  align-items: center;
  display: flex;
  height: 7.5px;
  justify-content: center;
  position: relative;
  width: 7.5px;
  z-index: 100;
  img {
    object-fit: cover;
    height: 45px;
    width: 45px;
  }
  &::before,
  &::after {
    background-image: linear-gradient(-180deg, #0076a3 0%, #072b3c 97%);
    content: "";
    height: 7.5px;
    position: absolute;
    transition: all 0.2s;
    transition-delay: 0.2s;
    transform: translateY(0px) translateX(0px);
    width: 7.5px;
    z-index: -1;
  }
  &::after {
    background-image: linear-gradient(0deg, #8c9a27 0%, #d3d821 97%);
  }
  &:hover {
    animation-name: hoverPop;
    animation-duration: 0.4s;
    &::before {
      animation: slick-hover-2 3s 0.4s linear infinite;
      transform: translateY(-20px) translateX(-20px);
    }
    &::after {
      animation: slick-hover 3s 0.4s linear infinite reverse;
      transform: translateY(20px) translateX(20px);
    }
  }
}

@keyframes hoverPop {
  0%,
  100% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.05);
  }
  66% {
    transform: scale(0.9);
  }
}

@keyframes slick-hover {
  0.00% {
    transform: translateY(20px) translateX(20px);
  }
  16.67% {
    transform: translateY(-13px) translateX(-9px);
  }
  33.33% {
    transform: translateY(4px) translateX(12px);
  }
  50.00% {
    transform: translateY(20px) translateX(-20px);
  }
  66.67% {
    transform: translateY(-3px) translateX(8px);
  }
  83.33% {
    transform: translateY(-15px) translateX(-11px);
  }
  100.00% {
    transform: translateY(20px) translateX(20px);
  }
}

@keyframes slick-hover-2 {
  0.00% {
    transform: translateY(-20px) translateX(-20px);
  }
  16.67% {
    transform: translateY(3px) translateX(-5px);
  }
  33.33% {
    transform: translateY(18px) translateX(-10px);
  }
  50.00% {
    transform: translateY(-20px) translateX(20px);
  }
  66.67% {
    transform: translateY(15px) translateX(-4px);
  }
  83.33% {
    transform: translateY(-1px) translateX(15px);
  }
  100.00% {
    transform: translateY(-20px) translateX(-20px);
  }
}

@media (max-width: 1260px) and (min-width: 1010px) {
  #hiddenTitle {
    display: none;
  }
  #appbar {
    min-height: 50px;
    max-height: 60px;
  }
  .usernameText {
    display: none;
  }

   #logoutButtonDevices {
    right: 0px;
  }
  }

  @media (max-width: 1010px) and (min-width: 501px) {
  #hiddenTitle {
    display: none;
  }
  #searchBar {
left:-10px;
}
#searchButton {
left:-10px;
}
  #appbar {
    min-height: 50px;
    max-height: 60px;
  }
  .usernameText {
    display: none;
  }

   #logoutButtonDevices {
    right: 0px;
  }
  /*----------- Home -------------*/
  #navButtonHomeDevices {
    font-size: 14px;
    min-width: 85px;
    left:10px;
  }

  /*----------- Domains -------------*/

   #navButtonDomainsDevices {
    font-size: 14px;
    min-width: 105px;
    left:10px;
  }

  /*----------- Devices -------------*/
#navButtonActiveDevices {
    font-size: 14px;
    min-width: 95px;
    left:10px;
  }

  /*----------- Users -------------*/
 #navButtonUsersDevices {
    font-size: 14px;
   min-width: 85px;
    left:10px;
  }

  /*----------- User, logout -------------*/
    #logoutButtonDevices {
    font-size: 14px;
    margin-right: 20px;
    min-width: 10px;
  }
#hiddenDeleteText{
display: none;
}

  #hiddenEditText {
    display: none;
  }
  #hiddenDeviceCreateText {
    display: none;
  }

  }
@media (max-width: 500px) and (min-width: 450px) {

td{
  font-size:  clamp(12px, 1.5vw, 1rem);
}
.modal-body{
    font-size:  clamp(12px, 1.5vw, 1rem);
  }

#searchBar {
left:-10px;
}
#searchButton {
left:-10px;
}
/* --------------- CREATE MODAL STYLE--------------------- */
#deviceName {
 
  float: right;
  margin-right: 50px;
}
#devicePhysicalId {
  
  float: right;
  margin-right: 50px;
}

  .tableCells {
    font-size:12px;
    width:50%;
  }
  /*----------- Navbar -------------*/
  #hiddenTitle {
    display: none;
  }

  .navText {
    display: none;
  }

  #navButtonHomeDevices {
    font-size: 100%;
    min-width: 10%;
    margin-left: 5%;
  }
  
  #navButtonUsersDevices {
   font-size: 100%;
    min-width: 10%;
  }

  #navButtonDomainsDevices {
    font-size: 100%;
    min-width: 10%;
  }
  
  #navButtonActiveDevices {
     font-size: 100%;
    min-width: 10%;
  }
  
  #profileIcon {
    position: relative;
  }
  #logoutButtonDevices {
    position: relative;
    float: left;
    right: 65%;
    border: 0;
    width: 10%;
    background-color: #20262e;
    position: relative;
    top: 0%;
  }
  .usernameText {
    display: none;
  }

  .devicesCard {
    margin:auto;
    overflow-y: clip;
    overflow-x: clip;
    
  }
  #devicesTable {
  max-width: 85vw;
  
  }
 
  #hiddenDeleteText {
    display: none;
  }
  #hiddenEditText {
    display: none;
  }
  #deviceEditButton {
    min-width:150%;
   float:left;
  }
  #deviceDeleteButton {
   min-width:150%;
   float:left;
  }
  #deviceCreateButton {
    min-width: 10%;
    max-width: 14%;
    align-items: center;
    margin-right:1%;
    
    
  }
  #hiddenDeviceCreateText {
    display: none;
  }

  #exportButton {
    align-items: center;
   min-width: 30%;
    max-width: 50px;
    top:0%;
    position: absolute;
  }



}
@media (max-width: 450px) and (min-width: 350px) {

/* #sureButtonEdit {
   font-size:12px;
  min-width:80px;
  width:90px;
}
#cancelButtonEdit {
  width:90px;
  min-width:80px;
  font-size:12px;
} */

/* --------------- CREATE MODAL STYLE--------------------- */
#deviceName {
 
  float: right;
  margin-right: 50px;
}
#devicePhysicalId {
  
  float: right;
  margin-right: 50px;
}

.tableCells {
    font-size:8px;
    width:50%;
  }
  /*----------- Navbar -------------*/
  #hiddenTitle {
    display: none;
  }

  .navText {
    display: none;
  }

  #navButtonHomeDevices {
    font-size: 100%;
    min-width: 10%;
    margin-left: 5%;
  }
  
  #navButtonUsersDevices {
   font-size: 100%;
    min-width: 10%;
  }

  #navButtonDomainsDevices {
    font-size: 100%;
    min-width: 10%;
  }
  
  #navButtonActiveDevices {
     font-size: 100%;
    min-width: 10%;
  }
  
  #profileIcon {
    position: relative;
  }
  #logoutButtonDevices {
    position: relative;
    float: left;
    right: 100%;
    border: 0;
    width: 10%;
    background-color: #20262e;
    position: relative;
    top: 0%;
  }
  .usernameText {
    display: none;
  }

  .devicesCard {
    margin:auto;
    overflow-y: clip;
    overflow-x: clip;
    
  }
  #devicesTable {
  max-width: 85vw;
  
  }
 
  #hiddenDeleteText {
    display: none;
  }
  #hiddenEditText {
    display: none;
  }
  #deviceEditButton {
    min-width:150%;
   float:left;
  }
  #deviceDeleteButton {
   min-width:150%;
   float:left;
  }
  #deviceCreateButton {
    min-width: 10%;
    max-width: 14%;
    align-items: center;
    margin-right:1%;
    
    
  }
  #hiddenDeviceCreateText {
    display: none;
  }

  #exportButton {
    align-items: center;
   min-width: 30%;
    max-width: 50px;
    top:0%;
    position: absolute;
  }






}

</style>